/* Knowledge Add Section */
.knowledgeadd {
  width: 100%;
  padding-inline: 50px;
  height: 100%;
}
@media (max-width: 1850px) {
  .knowledgebase {
    height: 80vh;
  }
}

/* Knowledge Add Header Section */
.knowledgeadd__header {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.knowledgeadd__header-button {
  padding: 10px 20px;
  background-color: var(--color__mode-inactive);
  color: #a4a4a4;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.knowledgeadd__header-button a {
  color: #a4a4a4;
  text-decoration: none; /* Remove underline */
}

.knowledgeadd__header-button.active {
  background-color: var(--color__secondary);
  color: var(--color__black);
}

.knowledgeadd__headerbar {
  background: var(--color__secondary);
  color: var(--color__black);
  height: 40px;
  padding: 12px;
  border-radius: 10px;
  text-align: left;
  margin-bottom: 20px;
}

.knowledgeadd__image-gallery {
  display: flex;
  gap: 10px;
}

.knowledgeadd__image-box {
  width: 150px;
  height: 150px;
  /* background: #333; */
  background: var(--color__dashboard-card);
  border: 1px solid #444;
}

/* Select Menu */
.knowledgeadd__select-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.knowledgeadd__custom-select {
  /* background-color: #1f2430; */
  background-color: var(--color__dashboard-card);
  color: var(--color__white);
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
}

.knowledgeadd__form-fields {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
  border: none;
  /* border: 1px solid #444; */
  /* padding: 5px; */
}

.knowledgeadd__form-fields label {
  /* flex: 1; */
  text-align: center;
  padding: 5px;
  border: 1px solid #444;
  color: var(--color__white);
}

.knowledgeadd__textarea-label {
  min-height: 200px;
  max-height: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.knowledgeadd__textarea-input {
  min-height: 200px;
  max-height: 500px;
}

.knowledgeadd__textarea-input-img {
  min-height: 200px;
  max-height: 500px;
  text-wrap-mode: nowrap;
}

.knowledgeadd__form-fields input,
.knowledgeadd__form-fields textarea {
  flex: 2;
  /* background: #222; */
  /* color: #fff; */
  background-color: var(--color__dashboard-card);
  color: var(--color__white);
  /* border: none; */
  padding: 5px;
  border: 1px solid #444;
}

/* section group Section */

.knowledgeadd__expandable-sections .knowledgeadd__section {
  background: #222;
  padding: 10px;
  margin-top: 5px;
}

.knowledgeadd__section {
  margin-bottom: 25px;
}

.knowledgeadd__section-header {
  background-color: var(--color__secondary);
  padding: 10px 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  /* transition: background-color 0.3s ease, padding 0.3s ease; */
  /* transition: all 0.4s ease-in-out; */
}

.knowledgeadd__section-header_X:hover {
  background-color: var(--color__primary); /* Warna berubah saat hover */
}

.knowledgeadd__section-header.expanded {
  /* background-color: var(--color__primary);
  padding: 12px 18px; */
  border-radius: 5px 5px 0 0;
  /* transform: scaleY(1.05); */
}

.knowledgeadd__section-header span:last-child {
  transition: transform 0.3s ease;
}

.knowledgeadd__section-header.expanded span:last-child {
  transform: rotate(180deg);
}

.knowledgeadd__section-header span {
  font-size: 16px;
}

.knowledgeadd__section-content {
  background-color: #444;
  padding: 10px 15px;
  margin-top: 5px;
  border-radius: 5px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
}

.knowledgeadd__section-content.expanded {
  max-height: 500px; /* Sesuaikan tinggi maksimal sesuai kebutuhan */
  opacity: 1;
}

/* Footer Section */
.knowledgeadd__footer {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 0fr 1fr;
  gap: 10px;
  color: var(--color__white);
}

.knowledgeadd__buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
  height: 50px;
}

.knowledgeadd__cancel,
.knowledgeadd__draft {
  background: var(--color__mode-inactive);
  color: #a4a4a4;
  padding: 20px;
  border: none;
  border-radius: 20px;
}

.knowledgeadd__submit {
  background: var(--color__secondary);
  color: var(--color__black);
  padding: 20px;
  border: none;
  border-radius: 20px;
}

.knowledgeadd__scraped-img {
  display: flex;
  flex-wrap: nowrap; /* Prevent images from wrapping to the next line */
  overflow-x: auto; /* Enable horizontal scrollbar if content overflows */
  margin: 0;
  max-width: 200%; /* Set the maximum width */
}
.knowledgeadd__scraped-img img {
  max-width: 200px; /* Adjust as needed */
  margin: 0px;
  border: 1px solid #ccc;
  display: block;
}

.knowledgeadd__success-dialog,
.knowledgeadd__error-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(0, 0, 0); /* Black background */
  padding: 20px;
  border: 1px solid #00e8d9; /* Thin blue border */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* White shadow */
  z-index: 1000;
  border-radius: 5px; /* Rounded corners */
  text-align: center;
}

.knowledgeadd__success-dialog p {
  color: white;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.knowledgeadd__error-dialog p {
  color: red;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.knowledgeadd__success-dialog button,
.knowledgeadd__error-dialog button {
  background-color: var(--color__secondary);
  border: none;
  color: #121212;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 50px;
  transition: background-color 0.3s ease;
  height: 40px;
  width: 80px;
}

.knowledgeadd__success-dialog button:hover,
.knowledgeadd__error-dialog button:hover {
  background-color: #00bfa5; /* Darker blue on hover */
}
.knowledgeadd__file-upload {
  display: flex; /* Use flexbox for layout */
}

.knowledgeadd__file-label {
  flex: 9; /* Left side takes 90% of the width */
  display: flex;
  align-items: center; /* Align label text and delete icon horizontally */
}

.knowledgeadd__file-delete-icon {
  cursor: pointer;
  margin-left: 10px; /* Add spacing between the file name and delete icon */
}

.knowledgeadd__file-upload-icon {
  cursor: pointer;
  width: 24px; /* Set the width of the upload icon */
  height: 24px; /* Set the height of the upload icon */
}

.knowledgeadd__input-tag {
  width: 100%;
}

.knowledgeadd__box-tag {
  width: 100%;
  padding: 8px;
  background-color: #222;
  border: 1px solid #444;
  color: #fff;
  /* border-radius: 6px; */
  outline: none;
  padding: 5px;
}

.knowledgeadd__tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}

.knowledgeadd__tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--color__secondary);
  color: black;
  padding: 10px;
  width: 140px;
  height: 40px;
  border-radius: 6px;
}

.knowledgeadd__remove-tagbtn {
  background: none;
  border: none;
  color: black;
  font-weight: bold;
  cursor: pointer;
  margin-left: 10px;
}
.knowledgeadd__remove-tagbtn:hover {
  color: red;
}

.knowledgeaddv2__header {
  background-color: #00ced1;
  color: black;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  border-bottom: 2px solid #00ced1;
}

.knowledgeaddv2__toggle-icon {
  font-size: 20px;
}

/* Collapsible Section */
.knowledgeadd__collapsible-group {
  margin-bottom: 20px;
}

.knowledgeadd__collapsible {
  background: #222;
  /* padding: 10px; */
  margin-top: 5px;
  border-radius: 5px;
  margin-bottom: 25px;
}

.knowledgeadd__collapsible-header {
  background-color: var(--color__secondary);
  padding: 10px 15px;
  border-radius: 5px;
  /* border-radius: 5px 5px 0 0; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  /* font-size: 18px;
  font-weight: bold; */
}

.knowledgeadd__table-container {
  width: 100%; /* Ensure the container takes the full width */
  overflow-x: auto; /* Enable horizontal scrolling if needed */
  /* border-collapse: collapse; */
  /* margin-top: 10px; */
}

.knowledgeadd__table-container table {
  width: 100%; /* Ensure the table takes the full width of the container */
  table-layout: fixed; /* Fix the table layout to prevent it from expanding */
  border-spacing: 0;
  /* border-collapse: collapse; */
}

.knowledgeadd__table-container th,
.knowledgeadd__table-container td {
  border: 1px solid #444;
  padding: 5px;
  text-align: center;
  /* background: black; */
  background-color: var(--color__black);
  color: var(--color__white);
  word-wrap: break-word; /* Ensure long words break to fit within the cell */
}

.knowledgeadd__table-container th {
  /* font-weight: bold; */
}

/* .knowledgeadd__table-container th:first-child,
.knowledgeadd__table-container td:first-child {
  width: 30%;
}

.knowledgeadd__table-container th:last-child,
.knowledgeadd__table-container td:last-child {
  width: auto;
} */

.knowledgeadd__input-text {
  width: 100%; /* Make the input take the full width of the table cell */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  /* background: #222; */
  background-color: var(--color__dashboard-card);
  color: var(--color__white);
  /* border: none; */
  padding: 8px;
  border: 1px solid #444;
}

.knowledgeadd__layout-type {
  display: flex;
  align-items: center;
}

.knowledgeadd__delete-btn {
  background: none;
  border: none;
  color: red;
  font-size: 18px;
  cursor: pointer;
  margin-right: 8px;
}

.knowledgeadd__bottom-bar {
  display: flex;
  justify-content: center;
  padding: 5px;
  background-color: var(--color__secondary);
  /* border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; */
  border-radius: 0 0 5px 5px;
}

.knowledgeadd__add-btn {
  background: none;
  border: none;
  /* font-size: 24px; */
  cursor: pointer;
}
