/* Knowledge Base Section */
.knowledgebase {
  width: 100%;
  padding-inline: 50px;
  height: 100%;
}

@media (max-width: 1850px) {
  .knowledgebase {
    height: 80vh;
  }
}

/* Knowledge Base Header Section */
.knowledgebase__header {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.knowledgebase__header-button {
  padding: 10px 20px;
  background-color: var(--color__mode-inactive);
  color: #a4a4a4;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.knowledgebase__header-button a {
  color: #a4a4a4;
  text-decoration: none; /* Remove underline */
}

.knowledgebase__header-button.active {
  background-color: var(--color__secondary);
  color: var(--color__black);
}

/* Knowledge Base Tabs Section */
.knowledgebase__tabs {
  background: var(--color__dashboard-card);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.knowledgebase__tab {
  flex: 1;
  text-align: center;
  padding: 10px;
  border: none;
  background: none;
  color: #bbb;
  font-size: 1rem;
  cursor: pointer;
}

.knowledgebase__tab.active {
  color: white;
  font-weight: bold;
  border-bottom: 2px solid var(--color__secondary);
}

.knowledgebase__cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.knowledgebase__card {
  display: flex;
  background-color: var(--color__dashboard-card);
  color: var(--color__white);
  border-radius: 10px;
  padding: 20px;
}

.knowledgebase__card-left {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.knowledgebase__image-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.knowledgebase__main-image {
  width: 150px;
  height: 150px;
  border-radius: 10px;
}

.knowledgebase__thumbnails {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.knowledgebase__thumbnail {
  width: 45px;
  height: 46px;
  border-radius: 5px;
  object-fit: cover;
  cursor: pointer; /* Change cursor to pointer on hover */
}

.knowledgebase__view-more {
  margin-top: 10px;
  color: #bbb;
  text-decoration: none;
  align-self: center;
}

.knowledgebase__card-middle {
  flex: 2;
  padding-left: 20px;
  position: relative;
}

.knowledgebase__title {
  font-size: 22px;
  margin-bottom: 10px;
}

.knowledgebase__subtitle {
  font-size: 18px;
  color: var(--color__white);
  margin-bottom: 10px;
}

.knowledgebase__description,
.knowledgebase__details {
  /* background-color: var(--color__primary); */
  color: var(--color__white);
  /* margin-bottom: 10px;
  line-height: 1.5; */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Batasi menjadi 2 baris */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3.2em; /* Sesuaikan dengan tinggi teks */
  line-height: 1.6em; /* Menyesuaikan jarak antar baris */
}

.knowledgebase__info {
  margin-bottom: 10px;
}

.knowledgebase__card-right {
  flex: 1;
  padding-left: 20px;
  position: relative;
}

.knowledgebase__price-section {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
}

.knowledgebase__property-id a {
  font-size: 14px;
  color: var(--color__secondary);
  margin-top: 5px;
  display: block;
}

.knowledgebase__actions {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  gap: 0px;
}

.knowledgebase__icon-button {
  padding: 10px;
  background-color: #121212;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.knowledgebase__marketplace-button {
  padding: 10px 20px;
  background-color: var(--color__secondary);
  color: #121212;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  height: 50px;
}

.knowledgebase__confirmation-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(0, 0, 0); /* Black background */
  padding: 20px;
  border: 1px solid #00e8d9; /* Thin blue border */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* White shadow */
  z-index: 1000;
  border-radius: 5px; /* Rounded corners */
  text-align: center;
}

.knowledgebase__confirmation-dialog p {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.knowledgebase__confirmation-dialog button {
  background-color: var(--color__secondary);
  border: none;
  color: #121212;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 50px;
  transition: background-color 0.3s ease;
  height: 40px;
  width: 80px;
}

.knowledgebase__confirmation-dialog button:hover {
  background-color: #00bfa5; /* Darker blue on hover */
}

/* knowledgeupload css */
.knowledgeupload__container {
  text-align: center;
  padding: 20px;
}
.knowledgeupload__preview-container {
  display: flex;
  overflow-x: auto;
  max-width: 80%;
  margin: auto;
}

.knowledgeupload__file-input-wrapper {
  margin-bottom: 10px;
}

.knowledgeupload__file-wrapper {
  position: relative;
  margin: 0px;
  display: inline-block;
}

.knowledgeupload__file-wrapper img {
  max-width: 200px; /* Adjust as needed */
  margin: 10px;
  border: 1px solid #ccc;
  display: block;
}

.knowledgeupload__preview {
  /* width: 200px; */
  height: 200px;
  object-fit: cover;
}
.knowledgeupload__button {
  display: block;
  margin-top: 5px;
  cursor: pointer;
  color: white;
  background-color: #00bfa5; /* Darker blue on hover */
  padding: 10px;
}
.knowledgeupload__upload-icon {
  font-size: 30px;
  cursor: pointer;
  border: 1px dashed gray;
  padding: 10px;
}

.knowledgeupload__add-files-icon {
  cursor: pointer;
  font-size: 24px;
  display: inline-block;
  padding: 10px;
  background: #ddd;
  margin: 10px;
}

.knowledgeupload__delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  padding: 5px;
  font-size: 14px;
}
.knowledgeupload__dialog {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
}
.knowledgeupload__dialog.success {
  background-color: #d4edda;
  color: #155724;
}
.knowledgeupload__dialog.error {
  background-color: #f8d7da;
  color: #721c24;
}

/* knowledgebasev2 css */
.knowledgebasev2__property-card {
  display: flex;
  flex-direction: column;
  color: white;
  padding: 10px;
  border-radius: 10px;
  max-width: 1200px;
  margin: auto;
  background-color: var(--color__dashboard-card);
}

.knowledgebasev2__top-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: #647505; */
}

.knowledgebasev2__left-section {
  flex: 1;
  /* background-color: #10006c; */
}

.knowledgebasev2__middle-section {
  flex: 2;
  padding: 0 20px;
  /* background-color: #166009; */
  width: 700px;
}

.knowledgebasev2__right-section {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  /* background-color: #6b3a03; */
}

.knowledgebasev2__logo-section {
  /* Membantu mendorong logo ke bagian bawah */
  /* margin-top: auto; */
  margin-top: 30px;
}

.knowledgebasev2__logo {
  max-width: 150px; /* Sesuaikan ukuran logo */
  height: auto;
}

.knowledgebasev2__image-container {
  display: flex;
  flex-direction: column;
}

.knowledgebasev2__main-image {
  width: 100%;
  border-radius: 10px;
}

.knowledgebasev2__thumbnail-grid {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.knowledgebasev2__thumbnail {
  width: 30%;
  border-radius: 5px;
}

.knowledgebasev2__details-grid {
  display: grid;
  /* grid-template-columns: auto 1fr; */
  justify-content: start;
  gap: 5px;
  margin-top: 10px;
}

.knowledgebasev2__details-grid div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.knowledgebasev2__label {
  color: var(--color__white);
  font-weight: bold;
  white-space: nowrap;
}

.knowledgebasev2__value {
  color: var(--color__white);
  text-align: right;
  flex-grow: 1;
  margin-left: 10px;
}

.knowledgebasev2__price {
  /* background-color: var(--color__dashboard-card); */
  color: var(--color__white);
}

.knowledgebasev2__property-id {
  /* background-color: var(--color__dashboard-card); */
  /* color: var(--color__white); */
  font-size: 14px;
  color: var(--color__secondary);
  margin-top: 5px;
  display: block;
}

.knowledgebasev2__bottom-section {
  display: flex;
  justify-content: flex-end; /* Menggeser ke kanan */
  align-items: center;
  padding: 15px 0px;
  /* border-top: 1px solid #ddd; */
  margin-top: 20px;
}

.knowledgebasev2__bottom-actions {
  display: flex;
  align-items: center;
  gap: 15px; /* Beri jarak antara ikon dan tombol */
}

.knowledgebasev2__icons {
  display: flex;
  gap: 10px;
}

.knowledgebasev2__icon-button {
  background: none;
  border: none;
  cursor: pointer;
}

.knowledgebasev2__icon-button img {
  width: 30px;
  height: 30px;
  transition: transform 0.2s ease-in-out;
}

.knowledgebasev2__icon-button:hover {
  transform: scale(1.1);
}

.knowledgebasev2__post-button {
  background-color: var(--color__secondary);
  border: none;
  padding: 10px 20px;
  color: #121212;
  /* font-weight: bold; */
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .knowledgebasev2__top-section {
    flex-direction: column;
  }

  .knowledgebasev2__middle-section {
    padding: 10px 0;
  }

  .knowledgebasev2__right-section {
    text-align: left;
  }
}
